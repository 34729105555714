<template>
  <v-container>
    <div class="titulo">1) Escolha a especialidade</div>
    <v-autocomplete
      v-model="especialidade"
      :items="especialidades"
      :loading="loading.especilidade"
      no-data-text="Especialidade não encontrada"
      color="green"
      outlined
      dense
      item-text="Especialidade"
      item-value="IdEspecialidade"
      return-object
      label="Especialidade"
      @change="getProfissional"
    />
    <div v-if="profissionais.length > 0">
      <div class="titulo">
        <div style="flex:1; padding: 5px;">2) Escolha o profissional de saúde </div>
        <div v-if="profissional.Valor>0" style="border: 1px solid black;border-radius:5%; padding:5px">Valor: R$ {{format(profissional.Valor)}}</div>
      </div>
      <v-autocomplete
        v-model="profissional"
        :items="profissionais"
        :loading="loading.profissional"
        no-data-text="Nenhum profissional encontrado"
        color="green"
        outlined
        dense
        item-text="nome"
        item-value="id"
        return-object
        label="Profissional da saúde"
      />
      <div v-if="profissional.MensagemExterna" style="padding-left: 20px; font-weight: bold;">
        Observação: {{profissional.MensagemExterna}}
      </div>
    </div>
    <div class="btnContainer">
      <v-btn
        @click="
          () =>
            callNextPage(profissional.id,profissional, especialidade)
        "
        :disabled="habilitarBotaoProximo()"
      >
        <v-img src="../assets/IconEcon.png" alt="Logo" class="img" />
        Prosseguir
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import BaseService from "../services/BaseService";
import {formatMoney} from "../util/";

export default {
  data() {
    return {
      especialidade: "",
      profissional: "",
      itemsEspecialidade: [],
      itemsProfissionais: [],
      loading: {
        especialidade: true,
        profissional: true,
      },
    };
  },
  computed: {
    especialidades() {
      let items;
      if (this.itemsEspecialidade)
        items = this.itemsEspecialidade.map((item) => ({
          Especialidade: item.Especialidade.toUpperCase(),
          IdEspecialidade: item.IdEspecialidade,
        }));
      return items;
    },
    profissionais() {
      let items;
      if (this.itemsProfissionais)
        items = this.itemsProfissionais.map((item) => ({
          nome: item.nome.toUpperCase(),
          id: item.id,
          MensagemExterna: item.MensagemExterna,
          Valor: item.Valor
        }));
      return items;
    },
  },
  async mounted() {
    localStorage.removeItem("dados");
    localStorage.removeItem("data");
    localStorage.removeItem("horario");
    const especialidadeService = new BaseService("especialidades/");
    try {
      const response = await especialidadeService.get();
      this.itemsEspecialidade = response.data;
      this.loading.especialidade = false;
    } catch (error) {
      console.log(error);
    } finally {
      this.loading.especialidade = false;
    }
  },
  methods: {
    callNextPage(id, profissional, especialidade){
      localStorage.setItem("idProfissional", id);
      localStorage.setItem("profissional",JSON.stringify(profissional));
      localStorage.setItem("especialidade" , JSON.stringify(especialidade));
      this.$router.push({
        name: 'SelecionaData',
        params: {
          profissional: this.profissional
        }
      })
    },
    format(value){
      return formatMoney(value);
    },
    habilitarBotaoProximo() {
      if (!this.especialidade) {
        return true;
      } else if (this.profissionais.length > 0) {
        if (!this.profissional) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    async getProfissional() {
      const profissionalService = new BaseService(
        `medicos/${this.especialidade.IdEspecialidade}`
      );
      this.profissional = "";
      try {
        const response = await profissionalService.get();
        this.itemsProfissionais = response.data;
        this.loading.profissional = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading.profissional = false;
      }
    },
  },
};
</script>
<style scoped>
.titulo {
  font-weight: bold;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.img {
  height: 28px;
  width: 28px;
  margin: 5px;
}
.btnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
</style>